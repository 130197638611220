<template>
    <div class="wrapper">
        <header-main></header-main>

        <main class="page page--pt">
            <section class="content content--mb">
                <div class="content__container">

                    <cashier-personal-info></cashier-personal-info>
                    
                </div>
            </section>
            <section class="content content--mb content--mb-very-big">
                <div class="content__container content__container__cash">
                    <div class="cash">
                        <div class="cash__cards">
                            <router-link :to="{name: 'CashierDepositType', params: {paysys_id: item.id, slug: item.slug}}" class="cash__card" v-for="item in paysystems">
                                <picture>
                                    <img :src="`${$config.ApplicationAPIURL}/paysys_img/${item.image}`"/>
                                </picture>
                                <div class="cash__card-name">{{ item.name }}</div>
                            </router-link>
                        </div>
                    </div>
                </div>
            </section>
        </main>

        <footer-main></footer-main>
    </div>
</template>

<script>
    export default {
        metaInfo () {
            return {
                title: this.$store.state.translation.cashier.deposit.meta.title,
                meta: [
                    {
                        name: 'title', 
                        content: this.$store.state.translation.cashier.deposit.meta.title
                    },
                    {
                        name: 'keywords', 
                        content: this.$store.state.translation.cashier.deposit.meta.keywords
                    },
                    {
                        name: 'description', 
                        content: this.$store.state.translation.cashier.deposit.meta.description
                    }
                ]
            }
        },
        data() {
            return {
                paysystems: []
            }
        },
        methods: {
            getPaysystems () {
                this.$axios.post('/cashier/paysystems/items', JSON.stringify({
                    type: 'deposit'
                }))
                .then((response) => {
                    this.setLoaded(true)
                    response = response.data
                    switch (response.status) {
                        case 'error':
                            this.$toast.error(response.message)
                            break
                        case 'success':

                            this.paysystems = response.data

                            this.setUser(response.user)
                            
                            break
                        default:
                            this.$toast.error(this.$store.state.translation.default.unknown_error)
                    }
                }).catch((error) => {
                    let response = error.response.data
                    this.setUser(response.user)

                    if(response && 'status' in response && response.status == 'error') {
                        this.$toast.error(response.message)
                    }
                })
            }
        },
        beforeMount() {
            this.setLoaded(false)
            this.getPaysystems()
        }
    }
</script>
