<template>
    <div class="wrapper">

        <header-main/>

        <main class="page">
            
            <home-banner/>

            <home-navigation-filters @games="setGames" :trigger_search="trigger_search" type="home"/>

            <home-game-slider/>

            <home-game-cards :games="games" @searchGames="searchGames"/>

            <section class="content">
                <div class="content__container">
                    <h1 class="content__title title title--center">{{ $store.state.translation.home.title }}</h1>
                    <div class="content__column">
                        <div class="content__col">
                            <h3 class="content__title mini-title">{{ $store.state.translation.home.contains }}</h3>
                            <nav class="content__navigation">
                                <ul class="content__list" data-anchor-links>
                                    <li><button type="button" data-goto="#benefits">{{ $store.state.translation.home.benefits }}</button></li>
                                    <li><button type="button" data-goto="#game">{{ $store.state.translation.home.game }}</button></li>
                                    <li><button type="button" data-goto="#bonuses">{{ $store.state.translation.home.bonuses }}</button></li>
                                    <li><button type="button" data-goto="#welcome-package">{{ $store.state.translation.home.welcome_package }}</button></li>
                                    <li><button type="button" data-goto="#personal-promotions">{{ $store.state.translation.home.personal_promotions }}</button></li>
                                    <li><button type="button" data-goto="#statuses">{{ $store.state.translation.home.statuses }}</button></li>
                                    <li><button type="button" data-goto="#cashback">{{ $store.state.translation.home.cashback }}</button></li>
                                    <li><button type="button" data-goto="#online-NEWs">{{ $store.state.translation.home.online_news }}</button></li>
                                    <!--<li><button type="button" data-goto="#payments">{{ $store.state.translation.home.payments }}</button></li>-->
                                    <li><button type="button" data-goto="#registration">{{ $store.state.translation.home.registration }}</button></li>
                                    <li><button type="button" data-goto="#support">{{ $store.state.translation.home.support }}</button></li>
                                    <li><button type="button" data-goto="#questions">{{ $store.state.translation.home.questions }}</button></li>
                                </ul>
                            </nav>
                        </div>
                        <div class="content__col">
                            <p>
                                {{ $store.state.translation.home.text1 }}
                            </p>
                            <p>
                                {{ $store.state.translation.home.text2 }}
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <div data-showmore class="show-more">
                <div data-showmore-content class="show-more__content" style="height: 0px; overflow: hidden;">
                    <section id="benefits" class="content">
                        <div class="content__container">
                            <h2 class="content__title title">{{ $store.state.translation.home.text3 }}</h2>
                            <div class="content__list-title">{{ $store.state.translation.home.text4 }}</div>
                            <ul class="content__list">
                                <li>{{ $store.state.translation.home.text5 }}</li>
                                <li>{{ $store.state.translation.home.text6 }}</li>
                                <li>
                                    {{ $store.state.translation.home.text7 }}
                                </li>
                                <li>{{ $store.state.translation.home.text8 }}</li>
                                <li>{{ $store.state.translation.home.text9 }}</li>
                                <li>{{ $store.state.translation.home.text10 }}</li>
                                <li>{{ $store.state.translation.home.text11 }}</li>
                                <li>{{ $store.state.translation.home.text12 }}</li>
                                <li>{{ $store.state.translation.home.text13 }}</li>
                            </ul>
                        </div>
                    </section>
                    <section id="game" class="content">
                        <div class="content__container">
                            <h2 class="content__title title">{{ $store.state.translation.home.text14 }}</h2>
                            <div class="content__list-title">{{ $store.state.translation.home.text15 }}</div>
                            <ul class="content__list">
                                <li>
                                    <span>{{ $store.state.translation.home.text16 }}</span> - {{ $store.state.translation.home.text17 }} 
                                </li>
                                <li>
                                    <span>{{ $store.state.translation.home.text18 }}</span> – {{ $store.state.translation.home.text19 }}
                                </li>
                                <li>
                                    <span>{{ $store.state.translation.home.text20 }}</span> – {{ $store.state.translation.home.text21 }}
                                </li>
                                <li><span>{{ $store.state.translation.home.text22 }}</span> – {{ $store.state.translation.home.text23 }}</li>
                                <li><span>{{ $store.state.translation.home.text24 }}</span> – {{ $store.state.translation.home.text25 }}</li>
                                <li><span>{{ $store.state.translation.home.text26 }}</span> – {{ $store.state.translation.home.text27 }}</li>
                                <li>
                                    {{ $store.state.translation.home.text28 }}
                                </li>
                            </ul>
                        </div>
                    </section>
                    <section id="bonuses" class="content">
                        <div class="content__container">
                            <h2 class="content__title title">{{ $store.state.translation.home.text29 }}</h2>
                            <p>
                                {{ $store.state.translation.home.text30 }}
                            </p>
                            <h3 id="welcome-package" class="content__middle-title">{{ $store.state.translation.home.text31 }}</h3>
                            <p>
                                {{ $store.state.translation.home.text32 }}
                            </p>
                            <table class="content__table table">
                                <caption>
                                    {{ $store.state.translation.home.text33 }}
                                </caption>
                                <thead>
                                    <tr>
                                        <th>{{ $store.state.translation.home.text34 }}</th>
                                        <th>{{ $store.state.translation.home.text35 }}</th>
                                        <th>{{ $store.state.translation.home.text36 }}</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    <tr v-for="item in $store.state.promo_templates">
                                        <td>
                                            <template v-if="item.deposit_index == 1">{{ $store.state.translation.home.text37 }}</template>
                                            <template v-if="item.deposit_index == 2">{{ $store.state.translation.home.text38 }}</template>
                                            <template v-if="item.deposit_index == 3">{{ $store.state.translation.home.text39 }}</template>
                                            <template v-if="item.deposit_index == 4">{{ $store.state.translation.home.text40 }}</template>
                                            <template v-if="item.deposit_index == 5">{{ $store.state.translation.home.text41 }}</template>
                                        </td>
                                        <td>
                                            <template v-if="item.type == 1">
                                                {{ $sprintf($store.state.translation.home.text42, item.amount, $store.state.user.data.merchant.currency_sign) }}
                                            </template>
                                            <template v-if="item.type == 2">
                                                {{ $sprintf($store.state.translation.home.text43, item.freespins_number) }}
                                            </template>
                                            <template v-else-if="item.type == 3">
                                                {{ item.percent }}% {{ $sprintf($store.state.translation.home.text44, item.max_deposit, $store.state.user.data.merchant.currency_sign) }}
                                            </template>
                                        </td>
                                        <td>{{ $sprintf($store.state.translation.home.text45, item.min_deposit, $store.state.user.data.merchant.currency_sign) }}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <p>
                                {{ $store.state.translation.home.text46 }}
                            </p>
                            <h3 id="personal-promotions" class="content__middle-title">{{ $store.state.translation.home.text47 }}</h3>
                            <p>
                                {{ $store.state.translation.home.text48 }}
                            </p>
                            <h3 id="statuses" class="content__middle-title">{{ $store.state.translation.home.text49 }}</h3>
                            <p>
                                {{ $store.state.translation.home.text50 }}
                            </p>
                            <table class="content__table table table--four">
                                <caption>
                                    {{ $store.state.translation.home.text51 }}
                                </caption>
                                <thead>
                                    <tr>
                                        <th>№</th>
                                        <th>{{ $store.state.translation.home.text52 }}</th>
                                        <th>{{ $store.state.translation.home.text53 }}</th>
                                        <th>{{ $store.state.translation.home.text54 }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in $store.state.promo_statuses">
                                        <td>
                                            <picture class="stars_home_status">
                                                <img src="../assets/img/star.svg" width="16" height="17" v-for="i in index + 1"/>
                                            </picture>
                                        </td>
                                        <td>{{ item.name }}</td>
                                        <td>
                                            <template v-if="index == 0">
                                                {{ $sprintf($store.state.translation.home.text55, '0', $store.state.user.data.merchant.currency_sign) }}
                                            </template>
                                            <template v-else>
                                                {{ $sprintf($store.state.translation.home.text55, parseFloat($store.state.promo_statuses[index].bets) + 1, $store.state.user.data.merchant.currency_sign) }}
                                            </template>

                                            
                                            <template v-if="index + 1 < $store.state.promo_statuses.length">
                                                {{ $sprintf($store.state.translation.home.text55, $store.state.promo_statuses[index + 1].bets, $store.state.user.data.merchant.currency_sign) }}
                                            </template>
                                            <template v-if="index + 1 >= $store.state.promo_statuses.length">
                                                {{ $store.state.translation.home.text56 }}
                                            </template>
                                        </td>
                                        <td>
                                            <template v-if="index == 0">
                                                {{ $sprintf($store.state.translation.home.text57, item.cashback) }}%.
                                            </template>
                                            <template v-else-if="index == 1">
                                                {{ $sprintf($store.state.translation.home.text58, item.cashback) }}%.
                                            </template>
                                            <template v-else-if="index == 2">
                                                {{ $sprintf($store.state.translation.home.text59, item.cashback) }}%.
                                            </template>
                                            <template v-else-if="index == 3">
                                                {{ $sprintf($store.state.translation.home.text59, item.cashback) }}%.
                                            </template>
                                            <template v-else-if="index == 4">
                                                {{ $sprintf($store.state.translation.home.text61, item.cashback) }}%.
                                            </template>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <h3 id="cashback" class="content__middle-title">{{ $store.state.translation.home.text62 }}</h3>
                            <p>
                                {{ $store.state.translation.home.text63 }}
                            </p>
                            <ul class="content__list">
                                <li>
                                    {{ $store.state.translation.home.text64 }}
                                </li>
                            </ul>
                            <p>
                                <span>{{ $store.state.translation.home.text65 }}</span> {{ $store.state.translation.home.text66 }}
                            </p>
                        </div>
                    </section>
                    <section id="online-NEWs" class="content">
                        <div class="content__container">
                            <h2 class="content__title title">{{ $store.state.translation.home.text67 }}</h2>
                            <p>
                                {{ $store.state.translation.home.text68 }}
                            </p>
                            <ul class="content__list">
                                <li>{{ $store.state.translation.home.text69 }}</li>
                                <li>{{ $store.state.translation.home.text70 }}</li>
                            </ul>
                            <p>
                                {{ $store.state.translation.home.text71 }}
                            </p>
                        </div>
                    </section>
                    <!--<section id="payments" class="content">
                        <div class="content__container">
                            <h2 class="content__title title">{{ $store.state.translation.home.text72 }}</h2>
                            <p>
                                {{ $sprintf($store.state.translation.home.text73, $store.state.user.data.merchant.min_deposit, $store.state.user.data.merchant.currency, $store.state.user.data.merchant.min_withdrawal, $store.state.user.data.merchant.currency) }}
                            </p>
                            <ul class="content__list content__list--mmb">
                                <li v-for="(item, index) in $store.state.promo_statuses">
                                    {{ $sprintf($store.state.translation.home.text74, item.name, item.max_withdrawal, $store.state.user.data.merchant.currency_sign) }}
                                </li>
                            </ul>
                            <p>
                                {{ $store.state.translation.home.text75 }}
                            </p>
                            <ul class="content__list content__list--mmb">
                                <li>Visa/Mastercard/Maestro;</li>
                                <li>WebMoney;</li>
                                <li>Skrill;</li>
                                <li>Piastrix;</li>
                                <li>Neteller;</li>
                                <li>Ecopayz;</li>
                                <li>Rapid Transfer;</li>
                                <li>Muchbetter;</li>
                                <li>Perfect Money;</li>
                                <li>Interac e-Transfer;</li>
                                <li>Interac Online;</li>
                                <li>Boleto;</li>
                                <li>Bitcoin/Litecoin/Ripple/Doge/Dash;</li>
                                <li>Express Connect.</li>
                            </ul>
                            <p>
                                {{ $store.state.translation.home.text76 }}
                            </p>
                        </div>
                    </section>-->
                    <section id="registration" class="content">
                        <div class="content__container">
                            <h2 class="content__title title">{{ $store.state.translation.home.text77 }}</h2>
                            <p>
                                {{ $store.state.translation.home.text78 }}
                            </p>
                            <p>
                                {{ $store.state.translation.home.text79 }}
                            </p>
                        </div>
                    </section>
                    <section id="support" class="content">
                        <div class="content__container">
                            <h2 class="content__title title">{{ $store.state.translation.home.text80 }}</h2>
                            <p>
                                {{ $store.state.translation.home.text81 }}
                            </p>
                            <ul class="content__list content__list--mmb">
                                <li>{{ $store.state.translation.home.text82 }}</li>
                                <li>{{ $store.state.translation.home.text83 }}</li>
                                <li>{{ $store.state.translation.home.text84 }}</li>
                                <li>{{ $store.state.translation.home.text85 }}</li>
                                <li>{{ $store.state.translation.home.text86 }}</li>
                            </ul>
                            <p>
                                {{ $store.state.translation.home.text87 }}
                            </p>
                            <p>
                                {{ $store.state.translation.home.text88 }}
                            </p>
                        </div>
                    </section>
                    <section id="questions" class="content content--mb-min">
                        <div class="content__container">
                            <h2 class="content__title title">{{ $store.state.translation.home.text89 }}</h2>
                            <div class="content__blocks">
                                <div class="content__block">
                                    <h3 class="content__middle-title content__middle-title--min">{{ $store.state.translation.home.text90 }}</h3>
                                    <p class="content__paragraph _decor-line">
                                        {{ $store.state.translation.home.text91 }}
                                    </p>
                                    <h3 class="content__middle-title content__middle-title--min">{{ $store.state.translation.home.text92 }}</h3>
                                    <p class="content__paragraph _decor-line">
                                        {{ $store.state.translation.home.text93 }}
                                    </p>
                                </div>
                                <div class="content__block">
                                    <h3 class="content__middle-title content__middle-title--min">{{ $store.state.translation.home.text94 }}</h3>
                                    <ul class="list _decor-line">
                                        <li>{{ $store.state.translation.home.text95 }}</li>
                                        <li>{{ $store.state.translation.home.text96 }}</li>
                                        <li>{{ $store.state.translation.home.text97 }}</li>
                                        <li>{{ $store.state.translation.home.text98 }}</li>
                                        <li>{{ $store.state.translation.home.text99 }}</li>
                                        <li>{{ $store.state.translation.home.text100 }}</li>
                                    </ul>
                                    <h3 class="content__middle-title content__middle-title--min">{{ $store.state.translation.home.text101 }}</h3>
                                    <p class="content__paragraph _decor-line">
                                        {{ $store.state.translation.home.text102 }}
                                        <router-link target="_blank" class="content__link" :to="{name: 'TermsConditions'}">{{ $store.state.translation.home.text103 }}</router-link>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </main>

        <footer-main></footer-main>
    </div>
</template>

<script>
    export default {
        metaInfo () {
            return {
                title: this.$store.state.translation.home.meta.title,
                meta: [
                    {
                        name: 'title', 
                        content: this.$store.state.translation.home.meta.title
                    },
                    {
                        name: 'keywords', 
                        content: this.$store.state.translation.home.meta.keywords
                    },
                    {
                        name: 'description', 
                        content: this.$store.state.translation.home.meta.description
                    }
                ]
            }
        },
        data() {
            return {
                games: [],
                trigger_search: null
            }
        },
        methods: {
            setGames (value) {    
                this.games = value       
            },
            searchGames () {
                this.trigger_search = Date.now()
            }
        },
        beforeMount() {
            if(window.self !== window.top) {
                window.parent.location = '/'
            }

            if(!this.$store.state.promo_statuses.length) {
                this.getPromoStatuses()
            }

            if(!this.$store.state.promo_templates.length) {
                this.getPromoTemplates()
            }
        },
        mounted() {
            document.querySelectorAll('button[data-goto]').forEach(item => {
                item.addEventListener('click', event => {
                    document.querySelector('.show-more').classList.add('_showmore-active')
                    document.querySelector('.show-more__content').removeAttribute('style')

                    let element = document.querySelector(event.target.getAttribute('data-goto'))
                    element.scrollIntoView()
                })
            })
        }
    }
</script>
