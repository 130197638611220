export default {
  ApplicationNAME: 'Woody-X',
  ApplicationAPI: 'https://woodyx-api.online/api/v1',
  ApplicationGamesAPI: 'https://games.woodyx-api.online/api/v1',
  ApplicationAPIURL: 'https://woodyx-api.online',
  ApplicationURL: 'https://woodyx3.online',
  Langs: ['ru', 'en'],
  RecaptchaV3: {
    token: '6Le9DQUoAAAAAC-kTrdU6zlFPZa56JU3xj21UAwM',
    action: 'casino'
  },
  RecaptchaV2: {
    token: '6Le9DQUoAAAAAC7yWYzCoxVn_Ftx2do_sYYfs0nH'
  },
  Timezone: 'Europe/Moscow'
}
